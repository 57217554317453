<template lang="pug">
.absolute.z-30.top-5.left-5.bg-white.right-5.bottom-5.grid.grid-cols-2.overflow-y-auto.overflow-x-hidden(
  style='width: 500px; grid-template-rows: auto auto auto 1fr; height: 95vh'
)
  .bg-white.mx-auto(style='width: 500px', @scroll='handleScroll')
    .cont.h-28.bg-white.w-full.z-50.inset-0.pt-3.px-8.transition-all.ease-out.duration-500.sticky(
      style='width: 100%'
    )
      .flex.justify-between.items-center.pb-4.mt-4
        .font-bold.text-darkblue.text-xl {{ $t("routesTrips.trip.addFromRoutes.title") }}
        i.el-icon-close.text-2xl.cursor-pointer.text-darkblue.font-semibold(
          @click='$emit("close")'
        )
      .bg-white.flex.justify-start.items-center.pb-4.mt-4
        skif-search(
          v-model='search',
          :placeholder='$t("search")',
          style='width: 450px; height: 35px'
        )

      .flex.justify-end.items-center.border.border-t-0.border-l-0.border-r-0.pb-3.bg-white.mb-6(
        :class='scrolled ? "shadow" : ""'
      )
        skif-button.mr-2.float-right(variant='normal', @click='$emit("close")') {{ $t("btn.cancel") }}
        skif-button.float-right(@click='addGeozone(); $emit("my-done")') {{ $t("add") }}

    table(
      style='width: 480px; margin-top: 100px; margin-left: 20px',
      v-if='filteredGeozone.length > 0'
    )
      thead
        tr
          th.bg-white.z-10.w-10.top-0
            skif-checkbox-old.mr-3(
              @click.native='(event) => event.stopPropagation()',
              v-model='isAllRoutesChecked',
              :indeterminate='isIndeterminate'
            )

          th.bg-white.fontLato.top-0(style='width: 220px')
            span.text-darkblue.leading-10.cursor-pointer.mr-2(
              style='font-size: 14px; color: #91b4e7'
            )
          th.bg-white.fontLato.top-0(style='width: 80px')
            //- span.text-darkblue.leading-10.cursor-pointer.ml-8(
            //-   style='font-size:14px; color: #91B4E7'
            //- )
            //-   transition(name='fade')
            //-     button.h-9.w-9.group.relative.link(
            //-       type='button',
            //-       v-if='routesSelected.length > 0',
            //-       @click='deleteGeozones'
            //-     )
            //-       svg.group-hover_fill-fillTrash.transition-colors.ease-in-out.duration-200(
            //-         width='24',
            //-         height='24',
            //-         xmlns='http://www.w3.org/2000/svg',
            //-         fill='#91B4E7'
            //-       )
            //-         path(
            //-           fill-rule='evenodd',
            //-           clip-rule='evenodd',
            //-           d='M9 5H6v2h12V5h-3l-1-1h-4L9 5zm8 3H7v10a2 2 0 002 2h6a2 2 0 002-2V8z'
            //-         )
            //-       skif-badge(list, :value='routesSelected.length')
      tbody(
        move-class='transition duration-1000',
        style='border-top: 4px solid white'
      )
        tr.cursor-pointer.hover_bg-hoverrow(v-for='route in filteredGeozone')
          td.w-10.pl-3
            skif-checkbox-old.pl-1(
              type='checkbox',
              v-model='routesSelected',
              :label='route.id'
            )
          td.ellipsis.pl-6(align='left')
            span.leading-10.nameTable {{ route.name }}
          //- td.pr-8(align='right')
          //-   img(src='../../assets/icons/edit-flight.svg', style='width: 18px')
</template>

<script>
import { mapState } from 'vuex'
// import { routesApi } from '@/api'
export default {
  data() {
    return {
      isMainShowen: true,
      isIndeterminate: false,
      routesSelected: [],
      scrolled: false,
      search: ''
    }
  },
  computed: {
    ...mapState('geozones', {
      geozones: (state) => state.geozones
    }),
    ...mapState('routes', {
      routes: (state) => state.fullRoutes
    }),
    isAllRoutesChecked: {
      get() {
        return this.routes
          ? this.routesSelected.length === this.routes.length
          : false
      },
      set(value) {
        const selected = []

        if (value) {
          this.routes.forEach(function (route) {
            selected.push(route.id)
          })
        }

        this.routesSelected = selected
      }
    },

    filteredGeozone() {
      return this.routes.filter((route) => {
        return route.name.toLowerCase().match(this.search.toLowerCase())
      })
    }
  },
  methods: {
    addGeozone() {
      const component = this
      // let selectedPoints = []
      const selectedRoutes = this.routes.filter((el) =>
        this.routesSelected.some((id) => el.id === id)
      )
      this.$store
        .dispatch('races/ADD_POINTS_FROM_ROUTES', selectedRoutes)
        .then((_) => component.$emit('close'))
      // this.$store
      //   .dispatch('races/ADD_POINTS_FROM_ROUTES', selectedRoutes.flat(Infinity))
    },
    deleteGeozones() {
      // console.log('Start deleting Routes...')
    },

    handleScroll(a) {
      this.scrolled = a.target.scrollTop > 0
    }
  },
  created() {
    // console.log('routes', this.routes)
    // console.log('geozones', this.geozones)
  }
}
</script>
<style scoped>
.buttons {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #91b4e7;
  mix-blend-mode: normal;
}
.buttons:focus {
  outline: none;
}
.isActive {
  color: #3c5e8f;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
}
.fade-enter-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
